<template>
    <div>
        <h4 class="mainTitle">{{ title }}</h4>
        <div class="view pa24" v-loading="loading">
            <div class="develop">
                <el-card shadow="never" class="box-card">
                    <div slot="header" class="clearfix">
                        <el-button type="primary" size="small" style="float: right" @click="getAuthorizer"
                            icon="el-icon-refresh-right" :loading="loadingWechatList" title="刷新小程序信息"></el-button>
                        <h4>开发信息</h4>
                    </div>
                    <el-alert title="注意事项" type="warning" description="不是通过我们平台开通的小程序无法修改名称、头像、介绍。小程序发布后无法修改名称、头像、介绍"
                        show-icon>
                    </el-alert>
                    <br />
                    <div class="cardBody">
                        <ul>
                            <li>
                                <label for="">
                                    <!-- <i class="el-icon-warning" @click="showScreenshotFun"></i> -->
                                    小程序名称：
                                </label>
                                <div class="deContent">
                                    <div>{{ develop.name }}</div>
                                    <div class="tips" v-html="updateTips.nick_name"></div>
                                    <div>
                                        <el-button v-if="updateTips.nick_name.indexOf('0') === -1" type="primary"
                                            size="small" @click="showUpdateModel(true, 'name')">修改</el-button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label for="">
                                    <!-- <i class="el-icon-warning" @click="showScreenshotFun"></i> -->
                                    小程序码：
                                </label>
                                <div class="deContent ewm">
                                    <div @click="showEwm = true">
                                        <el-avatar style="cursor: pointer" v-if="develop.ewm" shape="square" :size="100"
                                            :src="
                                                'https://api.zhengce365.com/wxapi.php?url=' +
                                                develop.ewm
                                            "></el-avatar>
                                        <div v-else>未授权</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label for="">认证类型：</label>
                                <div class="deContent2">{{ develop.type }}</div>
                            </li>
                            <!-- <li>
                                <label for="">回调URL：</label>
                                <div class="deContent2">
                                    <el-input placeholder="请输入内容" v-model="develop.url" disabled
                                        class="input-with-select">
                                        <el-button slot="append" @click="copyFun(develop.url)">复制</el-button>
                                    </el-input>
                                </div>
                            </li> -->
                            <!-- <li>
            <label for="">回调Token：</label>
            <div class="deContent2">
              <el-input
                placeholder="请输入内容"
                v-model="develop.token"
                disabled
                class="input-with-select"
              >
                <el-button slot="append" @click="copyFun(develop.token)"
                  >复制</el-button
                >
              </el-input>
            </div>
          </li>
          <li>
            <label for="">权限集：</label>
            <div class="deContent2">
              <div class="jurisdiction" v-if="develop.jurisdiction.length > 0">
                <div v-for="(v, i) in develop.jurisdiction" :key="i">
                  <span class="jStatus"
                    ><span>{{ statusData[v.status] }}</span
                    >：</span
                  >
                  <span class="jTitle">{{ v.title }}</span>
                  <span class="jDeatil">({{ v.content }})</span>
                </div>
              </div>
              <div v-else>无</div>
            </div>
          </li> -->
                            <!-- <li>
                                <label for="">技术支持水印：</label>
                                <div class="deContent2">{{ develop.technicalSupport }}</div>
                            </li> -->
                        </ul>
                    </div>
                </el-card>
                <el-card shadow="never" class="box-card">
                    <div slot="header" class="clearfix">
                        <el-button style="float: right" v-if="showPerfectInfoBtn" type="primary" size="small"
                            @click="showSetwxApplyInfo = true">完善小程序信息</el-button>
                        <h4>小程序信息</h4>
                    </div>
                    <div class="cardBody">
                        <ul>
                            <li>
                                <label for="">小程序APPID：</label>
                                <div class="deContent2">
                                    <el-input placeholder="请输入内容" v-model="develop.appid" disabled
                                        class="input-with-select">
                                        <el-button slot="append" @click="copyFun(develop.appid)">复制</el-button>
                                    </el-input>
                                </div>
                            </li>
                            <li>
                                <label for="">小程序原始ID：</label>
                                <div class="deContent2">
                                    <el-input placeholder="请输入内容" v-model="develop.xcxid" disabled
                                        class="input-with-select">
                                        <el-button slot="append" @click="copyFun(develop.xcxid)">复制</el-button>
                                    </el-input>
                                </div>
                            </li>
                            <li>
                                <label for="">
                                    <!-- <i class="el-icon-warning" @click="showScreenshotFun"></i> -->
                                    小程序头像：
                                </label>
                                <div class="deContent pic">
                                    <div>
                                        <el-avatar :size="50" alt="没有头像，请完善信息" :src="develop.pic"></el-avatar>
                                    </div>
                                    <div class="tips" v-html="updateTips.head_img"></div>
                                    <div>
                                        <el-button type="primary" size="small" v-if="
                                            updateTips.head_img.indexOf('0') === -1
                                        " @click="toggleShow()">修改</el-button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label for="">主体名称：</label>
                                <div class="deContent2">{{ develop.subject }}</div>
                            </li>
                            <li>
                                <label for="">主体类型：</label>
                                <div class="deContent2">
                                    {{ develop.principal_type_title }}
                                </div>
                            </li>
                            <li>
                                <label for="">小程序介绍：</label>
                                <div class="deContent">
                                    <div class="note">{{ develop.note }}</div>
                                    <div class="tips" v-html="updateTips.signature"></div>
                                    <div>
                                        <el-button type="primary" size="small" v-if="
                                            updateTips.signature.indexOf('0') === -1
                                        " @click="showUpdateModel(true, 'note')">修改</el-button>
                                    </div>
                                </div>
                                <!-- <div class="deContent2">{{ develop.note }}</div> -->
                            </li>
                            <li>
                                <label for="">
                                    <!-- <i class="el-icon-warning" @click="showScreenshotFun"></i> -->
                                    小程序服务类目：
                                </label>
                                <div class="serverType">
                                    <div>
                                        <div v-for="(serverType, index) in develop.serverType" :key="index">
                                            {{ serverType.join(" > ") }}
                                        </div>
                                    </div>
                                    <div>
                                        <el-button type="primary" @click="updateCategories" size="small" :loading="categoriesLoading">同步
                                        </el-button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label for="">
                                    <!-- <i class="el-icon-warning" @click="showScreenshotFun"></i> -->
                                    用户隐私保护指引设置：
                                </label>
                                <div class="deContent">
                                    <div></div>
                                    <div class="tips"></div>
                                    <div>
                                        <el-button type="primary" @click="updatePrivacySetting" size="small" disabled>更新隐私保护
                                        </el-button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-card>
                <el-card shadow="never" class="box-card">
                    <div slot="header" class="clearfix">
                        <!-- <div style="float: right; padding: 3px 0" type="text">
          <p>
            {{ new Date().toLocaleString() }}
          </p>
          <div>
            <el-dropdown split-button type="primary" size="mini">
              获取服务器域名
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>重置服务器域名</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div> -->
                        <el-button type="primary" size="small" @click="updateDomain" style="float: right" :loading="domainLoading">
                            同步</el-button>
                        <h4>服务器域名</h4>
                    </div>
                    <div class="cardBody">
                        <ul>
                            <li>
                                <label for="">request合法域名：</label>
                                <div class="deContent2">
                                    <el-input placeholder="请输入内容" v-model="develop.serverRequest" disabled
                                        class="input-with-select">
                                        <el-button slot="append" @click="copyFun(develop.serverRequest)">复制</el-button>
                                    </el-input>
                                </div>
                            </li>
                            <li>
                                <label for="">socket合法域名：</label>
                                <div class="deContent2">
                                    <el-input placeholder="请输入内容" v-model="develop.serverSocket" disabled
                                        class="input-with-select">
                                        <el-button slot="append" @click="copyFun(develop.serverSocket)">复制</el-button>
                                    </el-input>
                                </div>
                            </li>
                            <li>
                                <label for="">uploadFile合法域名：</label>
                                <div class="deContent2">
                                    <el-input placeholder="请输入内容" v-model="develop.serverUploadFile" disabled
                                        class="input-with-select">
                                        <el-button slot="append" @click="copyFun(develop.serverUploadFile)">复制
                                        </el-button>
                                    </el-input>
                                </div>
                            </li>
                            <li>
                                <label for="">downloadFile合法域名：</label>
                                <div class="deContent2">
                                    <el-input placeholder="请输入内容" v-model="develop.serverDownloadFile" disabled
                                        class="input-with-select">
                                        <el-button slot="append" @click="copyFun(develop.serverDownloadFile)">复制
                                        </el-button>
                                    </el-input>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-card>
            </div>
            <!-- 显示二维码 -->
            <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="showEwm" title="小程序二维码" width="300px"
                style="text-align: center">
                <el-avatar shape="square" :size="200" :src="'https://api.zhengce365.com/wxapi.php?url=' + develop.ewm">
                </el-avatar>
            </el-dialog>
            <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="showSetwxApplyInfo" title="完善小程序信息" width="90%">
                <el-card shadow="never" class="box-card">
                    <div class="cardBody">
                        <ul>
                            <li>
                                <label for="">
                                    <!-- <i class="el-icon-warning" @click="showScreenshotFun"></i> -->
                                    小程序名称：
                                </label>
                                <div class="deContent">
                                    <div>{{ develop.name }}</div>
                                    <div>
                                        <el-button type="primary" size="small" @click="showUpdateModel(true, 'name')">修改
                                        </el-button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label for="">
                                    <!-- <i class="el-icon-warning" @click="showScreenshotFun"></i> -->
                                    小程序头像：
                                </label>
                                <div class="deContent pic">
                                    <div>
                                        <el-avatar :size="50" alt="没有头像，请完善信息" :src="develop.pic"></el-avatar>
                                    </div>
                                    <div>
                                        <el-button type="primary" size="small" @click="toggleShow()">修改</el-button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label for="">小程序介绍：</label>
                                <div class="deContent">
                                    <div class="note">{{ develop.note }}</div>
                                    <div>
                                        <el-button type="primary" size="small" @click="showUpdateModel(true, 'note')">修改
                                        </el-button>
                                    </div>
                                </div>
                                <!-- <div class="deContent2">{{ develop.note }}</div> -->
                            </li>
                        </ul>
                    </div>
                </el-card>
            </el-dialog>
            <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="isShowUpdate" title="编辑小程序" width="600px">
                <el-form v-if="wxUpdateType === 'name'" :model="wxxcxUpdateData" :rules="wxxcxUpdateRules"
                    ref="wxxcxUpdateData" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="小程序名称：" prop="name">
                        <el-input v-model="wxxcxUpdateData.name" placeholder="请输入小程序名称">
                        </el-input>
                        <el-alert style="line-height: 18px; margin-top: 10px" v-if="wxxcxUpdateData.tips"
                            :title="wxxcxUpdateData.tips" type="warning" show-icon>
                        </el-alert>
                        <el-alert style="line-height: 18px; margin-top: 10px" v-if="wxxcxUpdateData.tips_name"
                            :title="wxxcxUpdateData.tips_name" type="warning" show-icon>
                        </el-alert>
                    </el-form-item>
                    <el-form-item label="组织机构代码证或营业执照 ：" v-if="develop.principal_type === 4">
                        <img-upload myClass="upload1" url="/api/file/getMedia" :file-list="certificatesFileList"
                            :params="imgUploadParams" :limit="1" @success="certificatesUploadSuccess"
                            @remove="certificatesUploadSuccess">
                        </img-upload>
                    </el-form-item>
                    <el-form-item label="其他资料：">
                        <img-upload myClass="upload2" url="/api/file/getMedia" :file-list="tipsNameFileList"
                            :params="imgUploadParams" :limit="5" @success="uploadSuccess" @remove="uploadSuccess">
                        </img-upload>
                    </el-form-item>
                    <el-form-item style="text-align: right">
                        <el-button type="primary" size="small" @click="setMiniProgramData('name')">修 改</el-button>
                    </el-form-item>
                </el-form>
                <el-form v-else-if="wxUpdateType === 'note'" :model="wxxcxUpdateData" :rules="wxxcxUpdateRules"
                    ref="wxxcxUpdateData" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="小程序介绍：" prop="note">
                        <el-input v-model="wxxcxUpdateData.note" placeholder="请输入小程序介绍" maxlength="120" rows="3"
                            type="textarea" show-word-limit>
                        </el-input>
                    </el-form-item>
                    <el-form-item style="text-align: right">
                        <el-button type="primary" size="small" @click="setMiniProgramData('note')">修 改</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <wxUpload field="file" @crop-upload-success="cropUploadSuccess" v-model="wxUpLoad.show" :width="100"
                :height="100" :url="wxUpLoad.url" :params="wxUpLoad.params" :headers="wxUpLoad.headers"
                img-format="png">
            </wxUpload>
        </div>
    </div>
</template>

<script>
import {
    setNickname,
    modifySignature,
    getCompanyAccountList,
    getAuthorizer,
    reDomain,
    setCategories
} from "@/api/authorization"
import wxUpload from "vue-image-crop-upload";
//功能也完善，接口缺少这个字段，所以注释
import imgUpload from "@/components/common/imgUpload.vue"
export default {
    name: "wxApplyDetails",
    components: {
        wxUpload,
        imgUpload
    },
    data() {
        return {
            loading: false,
            showEwm: false,//是否弹窗显示二维码
            title: "小程序详情", //页面标题
            loadingWechatList: false,
            //上传头像配置
            wxUpLoad: {
                // 上传器需要的数据
                url: "/api/file/modifyHeadImage",
                show: false,
                params: {
                    authorizerAppid: "",
                },
                headers: {
                    token: localStorage.getItem("token"),
                },
            },
            imgUploadParams: {},
            //修改次数提示
            updateTips: {
                nick_name: "",
                head_img: "",
                signature: "",
            },
            certificatesFileList: [],//修改名称 组织机构代码证或营业执照
            tipsNameFileList: [],//修改名称 发送资料
            showSetwxApplyInfo: false,//是否显示完善信息
            //修改小程序的内容
            wxxcxUpdateData: {
                pic: "", //修改头像路径
                name: "", //修改名称内容
                note: "", //修改介绍内容
                tips: "", //敏感名称提示
                tips_name: "", //组织类型小程序提示
                license: "",
                naming_other_stuff_1: "",
                naming_other_stuff_2: "",
                naming_other_stuff_3: "",
                naming_other_stuff_4: "",
                naming_other_stuff_5: "",
            },
            //已授权小程序信息
            develop: {
                name: "未授权",
                type: "未授权",
                url: "未授权",
                principal_type_title: "未授权",
                principal_type: "",
                ewm: "",
                token: "未授权",
                jurisdiction: [
                    {
                        status: 1,
                        title: "帐号管理权限",
                        content: "帮助小程序获取二维码，进行账号管理",
                    },
                    {
                        status: 1,
                        title: "物流助手权限",
                        content:
                            "帮助有物流需求的开发者，快速高效对接多家物流公司。对接后用户可通过微信服务通知接收实时物流状态，提升用户体验",
                    },
                    {
                        status: 1,
                        title: "即时配送权限",
                        content:
                            "旨在解决餐饮、生鲜、超市等小程序的外卖配送需求，接入后小程序商家可通过统一的接口获得多家配送公司的配送服务，提高经营效率",
                    },
                    {
                        status: 1,
                        title: "帐号管理权限",
                        content: "帮助小程序获取二维码，进行账号管理",
                    },
                ],
                technicalSupport: "未授权",
                appid: "未授权",
                xcxid: "未授权",
                pic: "",
                subject: "未授权",
                note: "未授权",
                serverType: [["未授权"]],
                serverRequest: "未授权",
                serverSocket: "未授权",
                serverUploadFile: "未授权",
                serverDownloadFile: "未授权",
            },
            //input表单验证
            wxxcxUpdateRules: {
                name: [
                    { required: true, message: "请输入小程序名称", trigger: "blur" },
                ],
                note: [
                    { required: true, message: "请输入小程序介绍", trigger: "blur" },
                ],
            },
            accountMap: {},//小程序配置信息
            wxApplyType: "",//小程序类型 user/crm
            wxUpdateType: "",//修改内容类型
            isShowUpdate: false,//是否显示修改内容
            authorizerAppid: "",
            categoriesLoading:false,//类目同步加载状态
            domainLoading:false,//域名同步加载状态
        };
    },
    watch: {
        $route() {
            let that = this;
            that.wxApplyType = that.$route.query.type;
            that.getAppListInfo();
        },
    },
    created() {
        let that = this;
        this.wxApplyType = that.$route.query.type;
        that.getAppListInfo();
    },
    computed: {
        //显示完善信息按钮
        showPerfectInfoBtn() {
            //头像
            if (
                this.develop.note &&
                this.develop.name &&
                this.develop.pic &&
                this.develop.note !== "无" &&
                this.develop.name !== "无"
            ) {
                return false;
            }
            return true;
        },
    },
    methods: {
        updatePrivacySetting() {//更新隐私保护
            //-console.log("待完成，更新隐私保护");
            this.$message({
                message: "待完成，更新隐私保护",
                type: "warning"
            });
        },
        async updateCategories() { //同步类目
            this.categoriesLoading=true;
            let res = await setCategories({ authorizerAppid: this.authorizerAppid })
            this.categoriesLoading=false;
            if (res.code==200) {
                this.$message({
                    message: "同步完成",
                    type: "success"
                });
                this.getAppListInfo();
            } else {
                this.$message({
                    message: this.$store.state.gzhErrcode[res.data.errcode],
                    type: "error"
                });
            }
        },
        async updateDomain() {//同步域名
            this.domainLoading=true;
            let res = await reDomain({ authorizerAppid: this.authorizerAppid })
            this.domainLoading=false;
            if (res.code===200) {
                this.$message({
                    message: "同步完成",
                    type: "success"
                });
                this.getAppListInfo();
            } else {
                this.$message({
                    message: this.$store.state.gzhErrcode[res.data.errcode],
                    type: "error"
                });
            }
        },
        //头像上传响应
        cropUploadSuccess(jsonData, field) {
            if (jsonData.code === 0) {
                this.wxUpLoad.show = false;
                this.getAuthorizer();
            } else {
                this.$message({
                    message: this.$store.state.gzhErrcode[jsonData.code],
                    tyep: "error"
                })
                document.getElementsByClassName('vicp-success')[0].innerHTML = `<i class="vicp-icon2"></i><span style="color:#d10">上传失败</span>`
            }
        },
        //上传修改名称需要的资料
        uploadSuccess(fileList) {
            this.wxxcxUpdateData.naming_other_stuff_1 = "";
            this.wxxcxUpdateData.naming_other_stuff_2 = "";
            this.wxxcxUpdateData.naming_other_stuff_3 = "";
            this.wxxcxUpdateData.naming_other_stuff_4 = "";
            this.wxxcxUpdateData.naming_other_stuff_5 = "";
            if (fileList) {
                for (var i in fileList) {
                    let file = fileList[i]
                    if (file.response.data) {
                        this.wxxcxUpdateData["naming_other_stuff_" + (parseInt(i) + 1)] =
                            fileList[i].response.data;
                    }
                }
            }
            ////-console.log(this.wxxcxUpdateData);
        },
        //上传修改名称需要的组织机构代码证或营业执照
        certificatesUploadSuccess(fileList) {
            if (fileList.length > 0) {
                this.wxxcxUpdateData.license = fileList[0].response.data
            } else {
                this.wxxcxUpdateData.license = "";
            }
        },
        //显示头像上传弹窗
        toggleShow() {
            this.wxUpLoad.show = !this.wxUpLoad.show;
        },
        copyFun(content) {
            if (
                content &&
                content != "无" &&
                content != "未授权" &&
                content != "无注册信息" &&
                content != "无可授权小程序"
            ) {
                //复制
                var tag = document.createElement('input');
                tag.setAttribute('id', 'cp_hgz_input');
                tag.value = content;
                document.getElementsByTagName('body')[0].appendChild(tag);
                document.getElementById('cp_hgz_input').select();
                document.execCommand('copy');
                document.getElementById('cp_hgz_input').remove();
                this.$message({
                    message: "复制完成",
                    type: "info"
                })

            } else {
                this.$message({
                    message: "无可复制内容",
                    type: "info"
                })
            }
        },
        async getAppListInfo() {//获取小程序appid
            this.loading = true;
            const result = await getCompanyAccountList({ appid: true })
            if (result.data) {
                let tmp = {};
                result && result.data.map(val => {
                    tmp[val.accountType] = val;
                });
                this.accountMap = tmp;
                this.getAuthorizer();
            } else {
                this.loading = false;
            }
        },
        async getAuthorizer() {//获取小程序授权信息
            if (this.wxApplyType == 'user' && this.accountMap[1]) {
                this.authorizerAppid = this.accountMap[1].appId
            } else if (this.wxApplyType == 'crm' && this.accountMap[2]) {
                this.authorizerAppid = this.accountMap[2].appId
            } else if (this.wxApplyType == 'yq' && this.accountMap[8]) {
                this.authorizerAppid = this.accountMap[8].appId
            } else if (this.wxApplyType == 'st' && this.accountMap[9]) {
                this.authorizerAppid = this.accountMap[9].appId
            } else if (this.wxApplyType == 'zw' && this.accountMap[10]) {
                this.authorizerAppid = this.accountMap[10].appId
            }
            this.wxUpLoad.params.authorizerAppid = this.authorizerAppid;
            this.imgUploadParams.authorizerAppid = this.authorizerAppid;
            this.loadingWechatList = true;
            let res = await getAuthorizer({ authorizerAppid: this.authorizerAppid });
            this.loading = false;
            this.loadingWechatList = false;
            if (res.data) {
                const principalTypeData = {
                    0: "个人",
                    1: "企业",
                    2: "媒体",
                    3: "政府",
                    4: "其他组织",
                };
                let verifyTypeTitle = "未认证";
                const verifyType = {
                    0: "微信认证",
                    1: "新浪微博认证",
                    2: "腾讯微博认证",
                    3: "已资质认证通过但还未通过名称认证",
                    4: "已资质认证通过、还未通过名称认证，但通过了新浪微博认证",
                    5: "已资质认证通过、还未通过名称认证，但通过了腾讯微博认证",
                };
                const account_basic_info = res.data.account_basic_info;
                const authorizer_info = res.data.authorizer_info;
                const effective_domain = res.data.effective_domain?res.data.effective_domain:{};
                const head_image_info = account_basic_info.head_image_info;
                const nickname_info = account_basic_info.nickname_info;
                const signature_info = account_basic_info.signature_info;
                try {
                    verifyTypeTitle = verifyType[authorizer_info.verify_type_info.id]
                } catch (err) {
                    verifyTypeTitle = "未认证";
                }
                this.updateTips = {
                    nick_name: "<div>一年内可申请修改" + nickname_info.modify_quota + "次</div><div>本年还可修改" + (nickname_info.modify_quota - nickname_info.modify_used_count) + "次</div",
                    head_img: "<div>一年内可申请修改" + head_image_info.modify_quota + "次</div><div>本年还可修改" + (head_image_info.modify_quota - head_image_info.modify_used_count) + "次</div>",//头像修改次数
                    signature: "<div>一月内可申请修改" + signature_info.modify_quota + "次</div><div>本月还可修改" + (signature_info.modify_quota - signature_info.modify_used_count) + "次</div",
                };
                let serverType = [];
                //服务类目
                if (authorizer_info.miniProgramInfo.categories) {
                    for (var row of authorizer_info.miniProgramInfo.categories) {
                        let typeName = [];
                        for (var i in row) {
                            let obj = row;
                            typeName.push(obj[i]);
                        }
                        serverType.push(typeName);
                    }
                }
                this.develop = {
                    name: account_basic_info.nickname,
                    type: verifyTypeTitle,
                    url: "",
                    principal_type_title: account_basic_info.principal_type?principalTypeData[account_basic_info.principal_type]:"",
                    principal_type: account_basic_info.principal_type,
                    ewm: authorizer_info.qrcode_url,
                    token: "无",
                    jurisdiction: [
                        {
                            status: 1,
                            title: "帐号管理权限",
                            content: "帮助小程序获取二维码，进行账号管理",
                        },
                        {
                            status: 1,
                            title: "物流助手权限",
                            content:
                                "帮助有物流需求的开发者，快速高效对接多家物流公司。对接后用户可通过微信服务通知接收实时物流状态，提升用户体验",
                        },
                        {
                            status: 1,
                            title: "即时配送权限",
                            content:
                                "旨在解决餐饮、生鲜、超市等小程序的外卖配送需求，接入后小程序商家可通过统一的接口获得多家配送公司的配送服务，提高经营效率",
                        },
                        {
                            status: 1,
                            title: "帐号管理权限",
                            content: "帮助小程序获取二维码，进行账号管理",
                        },
                    ],
                    technicalSupport: "无",
                    appid: account_basic_info.appid,
                    xcxid: authorizer_info.user_name,
                    pic: head_image_info?head_image_info.head_image_url:"",
                    subject: account_basic_info.principal_name,
                    note: signature_info.signature,
                    serverType: serverType,
                    serverRequest: effective_domain.requestdomain ? effective_domain.requestdomain.join(";") : "请完善域名设置",
                    serverSocket: effective_domain.wsrequestdomain ? effective_domain.wsrequestdomain.join(";") : "请完善域名设置",
                    serverUploadFile: effective_domain.uploaddomain ? effective_domain.uploaddomain.join(";") : "请完善域名设置",
                    serverDownloadFile: effective_domain.downloaddomain ? effective_domain.downloaddomain.join(";") : "请完善域名设置",
                }
            }
        },
        //显示编辑信息弹窗
        showUpdateModel(state, type) {
            this.isShowUpdate = state;
            this.wxUpdateType = type;
            //初始化提示信息和资料文件
            this.wxxcxUpdateData = {
                pic: "",
                name: "",
                note: "",
                tips: this.wxxcxUpdateData.tips,
                tips_name: this.wxxcxUpdateData.tips_name,
                license: "",
                naming_other_stuff_1: "",
                naming_other_stuff_2: "",
                naming_other_stuff_3: "",
                naming_other_stuff_4: "",
                naming_other_stuff_5: "",
            };

        },
        //修改小程序 名称 介绍
        setMiniProgramData(type) {
            let that = this;
            this.$refs["wxxcxUpdateData"].validate(async (valid) => {
                if (valid) {
                    //修改名称
                    if (type === "name") {
                        //检查名称是否通过
                        let params = {
                            nickname: that.wxxcxUpdateData.name,
                            authorizerAppid: that.develop.appid
                        };
                        if (that.wxxcxUpdateData.license) {
                            params.license = that.wxxcxUpdateData.license;
                        }
                        if (that.wxxcxUpdateData.naming_other_stuff_1) {
                            params.namingOtherStuff_1 = that.wxxcxUpdateData.naming_other_stuff_1;
                        }
                        if (that.wxxcxUpdateData.naming_other_stuff_2) {
                            params.namingOtherStuff_2 = that.wxxcxUpdateData.naming_other_stuff_2;
                        }
                        if (that.wxxcxUpdateData.naming_other_stuff_3) {
                            params.namingOtherStuff_3 = that.wxxcxUpdateData.naming_other_stuff_3;
                        }
                        if (that.wxxcxUpdateData.naming_other_stuff_4) {
                            params.namingOtherStuff_4 = that.wxxcxUpdateData.naming_other_stuff_4;
                        }
                        if (that.wxxcxUpdateData.naming_other_stuff_5) {
                            params.namingOtherStuff_5 = that.wxxcxUpdateData.naming_other_stuff_5;
                        }
                        let res = await setNickname(params)
                        if (res.code === 0) {
                            that.$message({
                                message: "修改完成",
                                type: "success"
                            });
                            that.certificatesFileList = []
                            that.tipsNameFileList = []
                            that.showUpdateModel(false, 'name');
                            that.getAuthorizer();
                        }

                    } else if (type === "note") {
                        //修改介绍
                        //检查名称是否通过
                        let res = await modifySignature({
                            signature: that.wxxcxUpdateData.note,
                            authorizerAppid: that.develop.appid
                        })
                        if (res.code === 0) {
                            that.$message({
                                message: "修改完成",
                                type: "success"
                            });
                            that.showUpdateModel(false, 'note');
                            that.getAuthorizer();
                        }
                    }
                } else {
                    return false;
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.develop h4 {
    height: 34px;
    line-height: 34px;
    font-size: 15px;
}

.mainTitle {
    font-size: 18px;
    padding: 20px 30px;
    background: #fff;
    border-bottom: 1px solid #eee;
}

.develop .v-modal {
    z-index: 2010 !important;
}

.develop .el-avatar {
    background: #fff;
}

.develop .el-select .el-input {
    width: 150px;
}

.develop .input-with-select .el-input-group__prepend {
    background-color: #fff;
}
</style>
<style lang="scss" scoped>
.appletCodeItem {
    line-height: 32px;
}

br {
    height: 10px !important;
    line-height: 10px !important;
}

.tx {
    margin: 0 auto;
}

.appletCode {
    padding: 15px;

    .box-card {
        margin-top: 10px;
    }
}

.develop {
    padding: 15px;

    .box-card {
        margin-top: 10px;


    }
}

.cardBody {
    >ul {
        list-style: none;
        padding: 0;
        margin: 0;
        border: 1px solid #E4E7ED;

        >li {
            display: flex;
            border-bottom: 1px solid #E4E7ED;
            font-size: 14px;
            font-weight: 400;

            >label {
                padding: 12px 10px;
                margin: 0;
                text-align: right;
                min-width: 200px;
                border-right: 1px solid #E4E7ED;
                background: #F5F7FA;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                color: #333;
                line-height: 32px;

                .el-icon-warning {
                    color: #67c23a;
                    cursor: pointer;
                    font-size: 25px;
                    margin-right: 7px;
                }
            }

            >.deContent2 {
                min-width: 500px;
                padding: 12px 10px;
                width: 100%;
                line-height: 32px;

                >.jurisdiction {
                    background: #f5f7fa;
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    line-height: 20px;

                    .jStatus span {
                        color: #69aa46;
                    }

                    .jDeatil {
                        color: #777777;
                    }
                }
            }

            >.deContent {
                padding: 12px 10px;
                color: #333;
                display: flex;
                line-height: 32px;
                min-width: 500px;
                width: 100%;
                justify-content: space-between;

                >.note {
                    width: 60%;
                    line-height: 20px;
                }

                >.tips {
                    color: #999;
                    line-height: 20px;
                }
            }

            >.serverType {
                padding: 12px 10px;
                color: #333;
                min-width: 500px;
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            >.pic {
                line-height: 50px;

                div:first-child {
                    height: 50px;
                    width: 50px;
                }

                div:last-child {
                    line-height: 50px;
                }
            }

            >.ewm {
                div:first-child {
                    height: 100px;
                    width: 100px;
                }

                div:last-child {
                    line-height: 100px;
                }
            }
        }

        >li:last-child {
            border: none;
        }
    }
}
</style>