<template>
  <div>
    <!-- :on-change="handleAvatarChange" -->
    <el-upload :class="myClass" class="upl" :action="url" list-type="picture-card" :headers="headers" :data="params"
      :file-list="fileList" :before-upload="beforeAvatarUpload" :on-success="success" :limit="limit"
      :on-preview="preview" :on-remove="remove" :on-progress="progress" :on-exceed="exceed">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :modal="false" :visible.sync="isMaxImg">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default: function () {
        return {};
      },
    },
    limit: {
      type: Number,
      default: 10
    },
    url: {
      type: String,
      default: "",
      require: true
    },
    fileList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    myClass: {
      type: String,
      default: "upload",
    },
  },
  data() {
    return {
      headers: {
        token: localStorage.getItem("token"),
      },
      dialogImageUrl: "",
      isMaxImg: false,
      fileSum: 0,
    };
  },
  // watch: {
  //   fileList: function (data) {
  //     ////-console.log($("."+this.myClass+" .el-upload--picture-card"));
  //     if (data.length >= 5) {
  //       $("." + this.myClass + " .el-upload--picture-card").css(
  //         "display",
  //         "none"
  //       );
  //     }
  //   },
  // },
  methods: {
    // handleAvatarChange(file, filelist) {
    //   this.imageUrl = URL.createObjectURL(file.raw);
    // },
    //验证图片类型/大小
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG或PNG 格式!", false);
      }
      if (!isLt1M) {
        this.$message.error("上传头像图片大小不能超过 10MB!", false);
      }
      return isJPG && isLt1M;
    },
    //上传成功回调
    success(res, file, fileList) {
      if ((res.code !== 0 && res.code !== 200) || !res.data) {
        fileList.pop();
      }
      if (fileList.length === this.limit) {
        document.querySelector("." + this.myClass + " .el-upload--picture-card").style.display = "none";
      }
      this.$emit("success", fileList);
    },
    preview(file) {
      this.isMaxImg = true;
      this.dialogImageUrl = file.url;
    },
    //删除图片回调
    remove(file, fileList) {
      ////-console.log(fileList);
      this.$emit("remove", fileList);
      document.getSelection
      document.querySelector("." + this.myClass + " .el-upload--picture-card").style.display = "inline-block"
    },
    //图片上传时回调
    progress(event, file, fileList) { },
    exceed() {
      this.$emit("exceed", this.limit)
    }
  },
};
</script>
<style lang="scss">
.upl .el-upload-list--picture-card .el-upload-list__item {
  width: 48% !important;
  height: 120px !important;
}

.upl .el-upload--picture-card {
  width: 48% !important;
  height: 120px !important;
  line-height: 120px !important;
}
</style>
